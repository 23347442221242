const es = {
    aftersales:{
        "contactus":"Contacta con nosotras para una respuesta rápida",
        "workorder":"lista de orden de trabajo",
        "createjob":"crear un trabajo",
        "submittinworkorder":"Al enviar una orden de trabajo, incluya tanta información detallada como sea posible, como capturas de pantalla de errores, información de la cuenta, etc., que son muy importantes para que podamos solucionar los problemas rápidamente.",
        "workordercontent":"Contenido de la orden de trabajo",
        "workorderreply":"Orden de trabajo Responder",
        "subscriptionaccount":"cuenta de suscripción",
        "problemtype":"tipo de problema",
        "tobeprocessed":"Para ser procesado",
        "reply":"responder",
        "processed":"Procesada, Ver respuestas",
        "revoked":"Revocada",
        "create":"Crear",
        "workorderno":"Orden de trabajo No",
        "product":"Producto",
        "status":"Estado",
        "type":"Tipo",
        "ticket":"Boleto",
        "createtime":"creartiempo",
    },
    excode:{
        "redemptioncode":"Canjear código",
        "pleaseredemptioncode":"Por favor ingresa el código de canje",
        "exchange":"Usar",
        "notused":"no usado",
        "used":"Usado",
        "expired":"Caducada",
        "status":"Estado",
        "exchangetime":"fecha de intercambio",
        "remark":"Observación",
        "createtime":"creartiempo",
    },
    footer:{
        "contactus":"Contacta con nosotras",
        "navigation":"Navegación",
        "home":"Hogar",
        "friends":"Amigas",
        "subscribe":"Suscribir",
        "aftersales":"Postventa",
        "help":"Ayuda",
        "termsofuse":"Condiciones de uso",
        "refundpolicy":"Política de reembolso",
        "privacypolicy":"política de privacidad",
        "helpcenter":"centro de ayuda",
        "paymentmethod":"Método de pago",
        "language":"Idioma",
    },
    friends:{
        "promotioninfo":"Información de promoción",
        "currentlevel":"nivel actual",
        "content":"Puede utilizar enlaces de invitación exclusivos y códigos de descuento para compartir con otros y ganar comisiones. Cuanto mayor sea el nivel, mayor será la comisión compartida",
        "rolemembers":"El papel de los miembros.",
        "promotionallinks":"Enlace de invitación",
        "discountcodes":"Códigos de descuento",
        "copy":"Copiar",
        "rebateratio":"Tasa de comisión",
        "discountratio":"Relación de descuento",
        "lowestdiscountratio":"Descuento de suscripción de usuario",
        "walletsandpoints":"Monederos y cantidad congelada",
        "applywithdrawal":"Después de solicitar el retiro, revisaremos y realizaremos el pago dentro de los 7 días hábiles.",
        "withdraw":"Retirar",
        "record":"Registro",
        "integral":"Integral",
        "wallet":"Carteras",
        "freezeamount":"Congelar",
        "pointsused":"Productos de YouTube congelados durante 3 días, otros productos congelados durante 1 día",
        "orderdetails":"Detalles del pedido",
        "orderamount":"Monto del pedido",
        "earnings":"Ganancias",
        "withdrawalsrecord":"Registro de retiros",
        "accounts":"Cuentas",
        "accounttype":"Tipo de cuenta",
        "withdrawalamount":"Cantidad",
        "cancel":"Cancelar",
        "submit":"Entregar",

        "greaterthan":"¡La cantidad debe ser mayor que 0!",
        "normal":"normal",
        "invalid":"inválido",
        "freeze":"Congelar",
        "withdrawing":"Retirándose",
        "extracted":"Extraído",

        "bankcard":"tarjeta bancaria",
        "promotionlink":"Enlace de promoción",
        "discountcode":"Código de descuento",
        "waitingforapproval":"Esperando aprobación",
        "waitingforpayment":"Esperando pago",
        "notpass":"no pasar",
        "paid":"Pagado",

        "cashwithdrawalid":"ID de retiro de efectivo",
        "accountsreceivable":"Cuentas por cobrar",
        "amount":"Monto(USD)",
        "status":"estado",
        "applicationtime":"tiempo de aplicación",
        "processingresults":"Resultados del procesamiento",

        "orderid":"ID de pedido",
        "useramount":"Cantidad de usuario",
        "duration":"duración",
        "promotiontype":"tipo de promoción",
        "createtime":"creartiempo",
        "eainings":"Ganancias(USD)",

        "receivingaccount":"cuenta receptora",
        "nowithdrawableamount":"Retiro mínimo de 30 USD",
        "copysuccessfully":"Copiar con éxito",
        "copyfailed":"Copia fallida",
        "activetime":"tiempo activo",
    },
    home:{
        'join':'Unirse',
        "texttitle":"Suscripción premium compartida con precio más bajo en",
        "buynow":"COMPRAR AHORA",
        "soldout":"ESCASEZ DE INVENTARIO",
        "shoptitle":"Después de realizar el pedido, verifique el mensaje de correo electrónico del correo electrónico de inicio de sesión actual (asegúrese de que la dirección de correo electrónico sea correcta)",
        "delivery":"ENTREGA EN TIEMPO REAL",
        "after":"Entrega en tiempo real después del pago sin esperas",
        "certificate":"CERTIFICADO SSl",
        "payments":"Los pagos se realizan en un entorno seguro con un certificado de seguridad SSL",
        "onlinesupport":"SOPORTE EN VIVO 24/7",
        "privatecustomer":"Nfxbus ofrece servicio al cliente privado en línea 24 horas al día, 7 días a la semana",
        "subscriber":"COABSCRIPTOR ADECUADO",
        "subscriptions":"Suscripción a prima de emisión a menor precio. Coincidencias prioritarias para co-suscriptores del mismo país",
        "moneyback":"GARANTÍA DE REEMBOLSO",
        "refund":"Tienes de 1 a 3 días para solicitar un reembolso (YouTube 3 días, otros productos, 24 horas)",
        "pwdreset":"RESET RÁPIDO CONTRASEÑA",
        "pwdresetdesc":"¿La PWD no funciona? Haga clic en restablecer contraseña en la página de suscripción sin espera ni operación manual",
        "parkingspace":"plaza de aparcamiento",
        "gift":"regalo",
        "screen":"Pantalla",
        "1screen":"1 perfil (compartido)",
        "maxnum":"perfiles (privado)",
        "month":"Mes",
        "buytyp":"Tipo de compra",
        "subscription":"suscripción",
        "voucher":"Bono(Compra de renovación)",
        "discountcode":"Código de descuento",
        "use":"Usar",
        "paymentmethod":"Método de pago",
        "pleasechoose":"por favor elige",
        "billingdetails":"Detalles de facturación",
        "payaddress":"Información de dirección de pago",
        "firstname":"Nombre de pila",
        "lastname":"Apellido",
        "emailaddress":"Dirección de correo electrónico",
        "phone":"Teléfono",
        "country":"País / Región",
        "state":"estado",
        "city":"Pueblo / Ciudad",
        "address":"dirección postal",
        "housenumber":"Número de casa y nombre de la calle.",
        "suiteunit":"Apartamento, suite, unidad, etc. (opcional)",
        "zipcode":"Código postal",
        "submit":"Entregar",
        "creditcard":"Tarjeta de crédito",
        "cardnumber":"Número de tarjeta",
        "expirydate":"Fecha de caducidad",
        "nameoncard":"Nombre en la tarjeta",
        "savethiscard":"Guarde esta tarjeta para pagos futuros",
        "play":"Jugar",
        "emailerror":"ERROR de correo electrónico",
        "payresluttitle":"Pago exitoso",

        "pleaseenter":"por favor ingresa",
        "selectamonth":"Por favor seleccione un mes",
        "selectthenumber":"Por favor seleccione el número de pantallas",
        "selectthepurchase":"Por favor seleccione el tipo de compra",
        "selectthepayment":"Por favor seleccione el método de pago",
        "discountcodenotempty":"¡El código de descuento no puede estar vacío!",
        "detail1":"<p>✅ Suscripción estable y renovable</p>\n" +
            "<p>✅ Registro de visualización independiente de alta definición 4K+HDR</p>\n" +
            "<p>✅ Entrega en tiempo real</p>\n" +
            "<p>✅ Soporte para terminal móvil, PC, TV, TV box</p>\n"+
            "<p>✅ Soporte posventa, reembolso sin preocupaciones</p>",
        "detail2":"<p>✅ Suscripción estable, tarifa prepaga de 12 meses</p>\n" +
            "<p>✅ Suscripción oficial compartida</p>\n" +
            "<p>✅ Entrega en tiempo real</p>\n" +
            "<p>✅ Soporte posventa, reembolso sin preocupaciones</p>",
        "detail3":"<p>✅ Suscripción estable, tarifa prepaga de 12 meses</p>\n" +
            "<p>✅ Clasificaciones de contenido: 18+/R18(21)/TV-MA</p>\n" +
            "<p>✅ Entrega en tiempo real</p>\n" +
            "<p>✅ Admite múltiples dispositivos: teléfono móvil, computadora, TV</p>\n" +
            "<p>✅ Soporte posventa, reembolso sin preocupaciones</p>",
        "detail5":"<p>✅ Utilice su suscripción personal de YouTube para unirse al grupo familiar premium</p>\n" +
            "<p>✅ Enviar enlace de invitación</p>\n" +
            "<p>✅ Mantenga sus listas y suscripciones</p>\n" +
            "<p>✅ Soporte posventa, reembolso sin preocupaciones</p>\n"+
            "<p>✅ Premium solo admite 1 persona, no puedes invitar a tus familiares o amigos</p>\n"
        // "<p>✅ Help: Before purchasing, check play.google.com if your account country is ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG , CZ, SE, PT, BE, IL.</p>"
        ,
        "detail7":"<p>✅ 6 personas comparten 1 suscripción</p>\n" +
            "<p>✅ ChatGPT Plus 4.0</p>\n" +
            "<p>✅ Disfruta de los GPT</p>\n" +
            "<p>✅ Disponible para complementos</p>\n" +
            "<p>✅ Prohibir API</p>\n" +
            "<p>✅ Reembolso sin preocupaciones dentro de las 24 horas</p>",

        "detail8":"<p>✅ 4 personas comparten 1 suscripción</p>\n" +
            "<p>✅ Entrega en tiempo real</p>\n" +
            "<p>✅ Soporte para terminal móvil, PC, TV, TV box</p>\n" +
            "<p>✅ Soporte posventa, reembolso sin preocupaciones</p>",
        "detail9":"<p>✅ Más de 110 millones de pistas en formato sin pérdida, HiRes FLAC y Dolby Atmos</p>\n" +
            "<p>✅ HIFI, biblioteca de música completa, sin publicidad, escucha sin conexión</p>\n" +
            "<p>✅ Admite la sincronización de música y listas de reproducción entre múltiples dispositivos, lo que facilita el cambio entre dispositivos como teléfonos móviles, computadoras y tabletas.</p>\n" +
            "<p>✅ Entrega en tiempo real, garantía y garantía de devolución de dinero.</p>",
        "detail10":"<p>✅ Plan premium, $3.88/mes/pantalla</p>\n" +
            "<p>✅ Enviar enlace de invitación en tiempo real</p>\n" +
            "<p>✅ Suscripción privada, conservar todo</p>\n" +
            "<p>✅ Tus favoritos y listas</p>\n" +
            "<p>✅ Garantía, garantía de reembolso.</p>",
        "detail11":"<p>✅ Más de 100 millones de fotos, vídeos y elementos premium, más de 3000 fuentes premium, más de 610 000 plantillas premium</p>\n" +
            "<p>✅ Cree diseños fácilmente con cambio de tamaño, eliminación de fondo y animaciones premium.</p>\n" +
            "<p>✅ Organiza tus materiales con un kit de marca, un planificador de contenido y 1 TB de almacenamiento.</p>\n" +
            "<p>✅ Utilizando diversas herramientas de IA para estimular la creatividad</p>",
        "detail12":"<p>✅ Disfruta de Super Duolingo</p>\n" +
            "<p>✅ Los miembros pueden disfrutar de un entorno de aprendizaje sin publicidad, evitando las interrupciones publicitarias al final de cada lección para que puedan concentrarse en estudiar.</p>\n" +
            "<p>✅ Utilice la función de \"práctica personalizada\" para corregir errores que ocurran durante la práctica.</p>\n" +
            "<p>✅ Los miembros pueden desafiar el nivel legendario avanzado un número ilimitado de veces para dominar por completo lo que han aprendido y mejorar sus habilidades lingüísticas.</p>",
        "title":"Por favor confirme la información del producto.",
        "price":"Precio",
        "product":"Nombre del producto",
        "note":"NOTA",
        "notetidal":"Después de una compra exitosa, verifique su cuenta y contraseña en la página de suscripción.",
        "notecontent":"Todas nuestras suscripciones se comparten y se venden como subcuentas; asegúrese de comprender esto antes de comprar.",
        "notegpt":"NFXBUS proporciona el GPT Plus oficial. La velocidad de generación de GPT depende de la velocidad de su red.",
        "notenetflix":"Si compró una membresía de Netflix Premium, revise su número de cuenta, contraseña y siga las instrucciones para usar la ubicación correcta en la página Suscripciones. Una vez que esté en su ubicación, establezca un pin para bloquear su información y evitar que otros la usen. Si la contraseña cambia, inicie sesión en la página de suscripción de NFXBUS para ver la nueva contraseña de la cuenta.",
        "notespotify":"Si ha adquirido una membresía de Spotify Premium, verifique su número de cuenta y contraseña en la página \"Suscripción\".",
        "notedisney":"Si compró una membresía premium de Disney+, verifique su número de cuenta, contraseña y siga las instrucciones para usar la ubicación correcta en la página \"Suscripción\". Una vez que esté en su ubicación, establezca un pin para bloquear su información y evitar que otros la usen. Si la contraseña cambia, inicie sesión en la página de suscripción de NFXBUS para ver la nueva contraseña de la cuenta.",
        "noteyoutube":"Si compró YouTube Premium, envíe su correo electrónico de inicio de sesión de YouTube en la página \"Suscripción\". Después del envío, el personal le enviará un enlace de invitación al grupo familiar de YouTube Premium a su correo electrónico. Por favor revisa tu dirección de correo electrónico y haz clic para aceptar.",
    },
    login:{
        "welcomelogin":"Bienvenido a iniciar sesión",
        "login":"Acceso",
        "updatepwd":"Cambiar la contraseña",
        "reg":"Cuenta registrada",
        "agreement":", usted acepta nuestros Términos de usuario y confirma que ha leído nuestra Política de privacidad",
        "quicklogin":"Inicio de sesión rápido",
        "sendcode":"Enviar código",
        "register":"Registro",
        "changepassword":"Cambiar la contraseña",
        "verificationcode":"Código de verificación",
        "name":"Nombre",
        "password":"Contraseña",
        "agapassword":"Repita la contraseña",
        "modify":"Modificar",
        "validatepswlen":"La contraseña reúne letras mayúsculas y minúsculas, números y caracteres especiales, cualquier combinación de tres de ellos, ¡y la longitud es de entre 8 y 16!",
        "checkagreement":"Por favor revisa el acuerdo",
        "passwordinconsistency":"¡Las contraseñas ingresadas dos veces son inconsistentes!",
        "passwordagain":"por favor ingrese la contraseña nuevamente",
        "verificationsend":"El código de verificación ha sido enviado, preste atención para verificar",
    },
    luckdraw:{
        "lotteryticket":"Billete de lotería",
        "integral":"integral",
        "welcome":"Bienvenido a nfxbus, esta es nuestra página de actividades, haz clic en la tarjeta para sortear premios con un solo clic,\n" +
            " los premios incluyen varios paquetes de cuentas gratuitos,\n" +
            " Códigos de descuento y otros descuentos, gracias por su apoyo y confianza.",
        "none":"sin premio",
        "redemptioncode":"Canjear código",
        "copy":"Copiar",
        "prizelist":"lista de premios",
        "notused":"no usado",
        "used":"Usado",
        "expired":"Caducado",

        "prizeno":"Premio nº",
        "contents":"Contenido",
        "exchangedate":"fecha de intercambio",
        "createtime":"creartiempo",
        "exchange":"intercambio",
        "insufficientpoints":"Puntos insuficientes",
        "consumepoints":"Esta operación consumirá 10 puntos. ¿Continuar?",
        "tips":"Consejos",
        "comfirm":"confirmar",
        "cancel":"Cancelar",
        "failtowin":"No ganar la lotería",
        "congratulations":"Felicitaciones por obtener",
        "copysucceeded":"¡Copiado exitosamente!",
        "copyfailed":"¡La copia falló!",

    },
    subscribe:{
        "renewnow":"Renovar ahora",
        "subscription":"Renovar la misma suscripción sin cambiar",
        "independent":"4K+HDR HD, grabación de visualización independiente",
        "guarantee":"garantía de devolución de dinero",
        "timeleft":"Tiempo restante",
        "openingtime":"hora de apertura de cuenta",
        "endtime":"Hora de finalización",
        "account":"Cuenta",
        "password":"Contraseña",
        "profile":"Perfil",
        "pleaseuse":"por favor use",
        "subaccount":"subcuenta",
        "myprofile":"¡No ocupes el puesto de otra persona!",
        "renew":"Renovar",
        "aftersales":"Después de las ventas",

        "yourownsub":"1. Utilice su propia subcuenta según el número de serie.",
        "delete":"2.No modifique/elimine otras subcuentas",
        "modifysubscription":"3.No modifique la información relacionada con la suscripción.",
        "reminder":"Recordatorio: si tiene algún problema, comuníquese con el servicio de atención al cliente a tiempo, compre una suscripción en nfxbus, disfrute del servicio de atención al cliente VIP y no necesite cambiar el número para la renovación.",

        "youtubeloginemail":"Ingrese su correo electrónico de inicio de sesión de YouTube. Debe registrarse en un grupo en el hogar utilizando la dirección de correo electrónico de inicio de sesión; de lo contrario, será expulsado del hogar. Si necesita ayuda, comuníquese con el servicio de atención al cliente de Nfxbus por correo electrónico.",
        "inspectaddress":"Verifique el país/región de su cuenta: KR, ES, FR, PL, IT, DE, NL, GB, CA, US, JP, GR, CH, LT, PA, SG, CZ, SE, PT, BE, Illinois. Si tiene algún problema, comuníquese con los empleados a través de tickets dentro del tiempo designado.",
        "aftersalesservice":"servicio posventa",
        "support":"página de soporte.",
        "submit":"Entregar",

        "buyit":"Aún no hay suscripciones, date prisa para comprarla.",
        "paymentsucceeded":"Pago exitoso",
        "month":"Por favor seleccione un mes",
        "number":"Por favor seleccione el número de pantallas",
        "purchase":"Por favor seleccione el tipo de compra",
        "payment":"Por favor seleccione el método de pago",
        "name":"Por favor ingrese el nombre en la tarjeta",
        "card":"Por favor ingrese el número de tarjeta",
        "cvv":"Por favor introduce CVV",
        "expirydate":"Por favor ingrese la fecha de vencimiento",
        "processing":"Se ha enviado el estado de procesamiento. Verifique el estado de procesamiento en la orden de trabajo.",
        "cannotempty":"¡El código de descuento no puede estar vacío!",
        "remind":"Si enviar mensaje",
        "originalpassword":"La contraseña original para esta operación ya no se utilizará. ¿Se debe continuar?",
        "passwordmeets":"La contraseña reúne letras mayúsculas y minúsculas, números y caracteres especiales, cualquier combinación de tres de ellos, ¡y la longitud es de entre 8 y 16!",
        "pwdupdatetime":"Password change",
        "pwdupdatetips":"Enviado, espere pacientemente",

        "getverlink":"Obtener enlace de verificación de inicio de sesión",
        "ignorefeature":"Si no se le ha pedido que complete el código de verificación, ignore esta función.",
        "openlink":"abre el enlace",
        "clicklink":"Haga clic para obtener el enlace",
        "getGptLoginCode":"Obtener el código de inicio de sesión de GPT",
        "ignorecode":"Si no necesita obtener el código de inicio de sesión, ignórelo.",
        "getcode":"Haga clic para obtener el código",
    },
    userinfo:{
        "signout":"desconectar",
        "nickname":"Apodo",
        "email":"Correo electrónico",
        "code":"Código",
        "vercode":"Código de verificación",
        "avatar":"Avatar",
        "uploadavatar":"Por favor sube tu avatar",
        "logout":"¿Estás seguro de que quieres cerrar sesión?",
        "onlyjpgpng":"Las imágenes cargadas sólo pueden estar en JPG | formato png",
        "cannotexceed":"¡El tamaño de la imagen cargada no puede exceder los 5 MB!"
    },
    wish:{
        "wish":"Desear",
        "help":"Nfxbus elegirá algunos deseos para ayudar.",
        "christmas":"Se hacen realidad cuando llega la Navidad, deja tu deseo.",
        "submit":"Entregar",
        "mywish":"mi deseo"
    },
    map:{
        "home":"Hogar",
        "userinfo":"información de usuario",
        "login":"Acceso",
        "withfriends":"Con amigos",
        "subscribe":"Suscribir",
        "aftersales":"Postventa",
        "luckdraw":"Sorteo",
        "wish":"Desear",
        "helpcenter":"Centro de ayuda",
        "tradingstamps":"sellos comerciales",
    },
}

export default es
