<template>
    <div id="app" style="height: 100%">
      <router-view  v-if="isRouterAlive"/>
    </div>
</template>


<script>
  import store from '@/store/index';
  import { getIpCountry } from "@/request/requests";
  import { MessageError } from "@/util/util"
  import Bus from "@/util/Bus";
  import { gapi } from "gapi-script";
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:"801847800065-mvegjp8mtfeb2155m1e5rhi0ss5eqkgu.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });
  import cn from 'element-ui/lib/locale/lang/zh-CN';
  import en from 'element-ui/lib/locale/lang/en';
  import kr from 'element-ui/lib/locale/lang/ko';
  import ar from 'element-ui/lib/locale/lang/ar';
  import es from 'element-ui/lib/locale/lang/es';
  import fr from 'element-ui/lib/locale/lang/fr';
  import pt from 'element-ui/lib/locale/lang/pt';
  import it from 'element-ui/lib/locale/lang/it';
  import moment from 'moment';
  import 'moment/locale/ka';
  moment.locale('kr');

  export default {
    data() {
      return {
        locale: kr,
        language:"",
        isRouterAlive:true,
      }
    },
    provide() {
      return {
        reload: this.reload,
      }
    },
    methods:{
      reload() {
        this.isRouterAlive = false
        // 刷新页面
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      },
      async getIpCountryInfo() {
        let res = await getIpCountry()
        if (res.status == "200") {
          this.language=res.data
          console.log(this.language)
          this.toSetLang()
        } else {
          MessageError(res.msg)
        }
      },
      setMenu(){
        store.commit('setLoginStatus',true)
        if (localStorage.lang=='kr'){
          store.commit('setMenuListData', store.state.map.menuListLogin)
        }else if(localStorage.lang=='en'){
          store.commit('setMenuListData', store.state.map.menuListLoginEn)
        }else if(localStorage.lang=='ar'){
            store.commit('setMenuListData', store.state.map.menuListLoginAr)
        }else if(localStorage.lang=='es'){
            store.commit('setMenuListData', store.state.map.menuListLoginEs)
        }else if(localStorage.lang=='fr'){
            store.commit('setMenuListData', store.state.map.menuListLoginFr)
        }else if(localStorage.lang=='pt'){
            store.commit('setMenuListData', store.state.map.menuListLoginPt)
        }else if(localStorage.lang=='it'){
            store.commit('setMenuListData', store.state.map.menuListLoginIt)
        }
      },
      toSetLang(){
        if (this.language.toLowerCase()=='kr'){
          localStorage.setItem("lang",'kr')
          this.$i18n.locale = 'kr'
          this.locale = kr
          moment.locale('kr');
        }else if (this.language.toLowerCase()=='en'){
          localStorage.setItem("lang",'en')
          this.$i18n.locale = 'en'
          this.locale = en
          moment.locale('en');
        }else if (this.language.toLowerCase()=='cn'){
            localStorage.setItem("lang",'cn')
            this.$i18n.locale = 'cn'
            this.locale = cn
            moment.locale('cn');
        }else if (this.language.toLowerCase()=='ar'){
            localStorage.setItem("lang",'ar')
            this.$i18n.locale = 'ar'
            this.locale = ar
            moment.locale('ar');
        }else if (this.language.toLowerCase()=='es'){
            localStorage.setItem("lang",'es')
            this.$i18n.locale = 'es'
            this.locale = es
            moment.locale('es');
        }else if (this.language.toLowerCase()=='fr'){
            localStorage.setItem("lang",'fr')
            this.$i18n.locale = 'fr'
            this.locale = fr
            moment.locale('fr');
        }else if (this.language.toLowerCase()=='pt'){
            localStorage.setItem("lang",'pt')
            this.$i18n.locale = 'pt'
            this.locale = pt
            moment.locale('pt');
        }else if (this.language.toLowerCase()=='it'){
            localStorage.setItem("lang",'it')
            this.$i18n.locale = 'it'
            this.locale = it
            moment.locale('it');
        }

        console.log(localStorage.lang)

        this.setMenu()

        // localStorage.lang == undefined ? localStorage.setItem("lang",'kr'):''

        Bus.$on('switchLanguage',(value)=>{
          localStorage.lang = value
          let lang = localStorage.lang
          console.log(lang)
          if (lang == 'cn'){
            this.$i18n.locale = 'cn'
            this.locale = cn
            moment.locale('cn');
          }
          if (lang == 'en'){
            this.$i18n.locale = 'en'
            this.locale = en
            moment.locale('en');
          }
          if (lang == 'kr'){
            this.$i18n.locale = 'kr'
            this.locale = kr
            moment.locale('kr');
          }
          if (lang == 'ar'){
            this.$i18n.locale = 'ar'
            this.locale = ar
            moment.locale('ar');
          }
          if (lang == 'es'){
            this.$i18n.locale = 'es'
            this.locale = es
            moment.locale('es');
          }
          if (lang == 'fr'){
            this.$i18n.locale = 'fr'
            this.locale = fr
            moment.locale('fr');
          }
          if (lang == 'pt'){
            this.$i18n.locale = 'pt'
            this.locale = pt
            moment.locale('pt');
          }
          if (lang == 'it'){
            this.$i18n.locale = 'it'
            this.locale = it
            moment.locale('it');
          }
          this.setMenu()
        })
      }
    },
    mounted() {
      if (window.location.href.indexOf("?uc=")>-1){
          let start=window.location.href.indexOf("?uc=")
          console.log(start)
          let usercode=window.location.href.substr(start+4,8)
          console.log(usercode)
          if (usercode){
            this.$cookies.set("usercode",usercode)
          }
      }
        if (window.location.href.indexOf("helpdetail?id=")>-1){
            let start=window.location.href.indexOf("helpdetail?id=")
            let id=window.location.href.substr(start+14)
            if (id){
                this.$cookies.set("helpdetailid",id)
            }
        }
    },
    created() {
      this.getIpCountryInfo()
      if (window.location.href.indexOf("/plat")>-1){
        this.$router.push({ path:store.state.map.currentRoute})
      }else {
        this.$router.push({ path: "/plat/home" });
      }
    }
  }
</script>

<style lang="css" src="./assets/common.css">
</style>
<style lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: center;
    color: #2c3e50;
    height: 100%;
    .shop-compare-table{
      .ant-table-fixed-header .ant-table-scroll .ant-table-header{
        margin-bottom: -17px !important;
        padding-bottom: 0px !important;
      }
      .ant-table-tbody > tr > td {
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    .editable-add-btn{
      margin-bottom: 10px;
    }
    .mgr-btm-10{
      margin-bottom: 10px;
    }
    .hide-clear{
      .ant-select-selection:hover .ant-select-selection__clear{
        opacity: 0;
      }
    }
  }
</style>
<style>
  /deep/ button{
    color: white;
  }
  @media screen and (max-width: 768px){
    .el-message-box{
      width: 350px !important;
    }
    .el-message{
      width: 350px !important;
    }
  }
</style>
